exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-detail-country-tsx": () => import("./../../../src/pages/detail/country.tsx" /* webpackChunkName: "component---src-pages-detail-country-tsx" */),
  "component---src-pages-detail-network-tsx": () => import("./../../../src/pages/detail/network.tsx" /* webpackChunkName: "component---src-pages-detail-network-tsx" */),
  "component---src-pages-detail-operator-tsx": () => import("./../../../src/pages/detail/operator.tsx" /* webpackChunkName: "component---src-pages-detail-operator-tsx" */),
  "component---src-pages-detail-region-tsx": () => import("./../../../src/pages/detail/region.tsx" /* webpackChunkName: "component---src-pages-detail-region-tsx" */),
  "component---src-pages-geography-js": () => import("./../../../src/pages/geography.js" /* webpackChunkName: "component---src-pages-geography-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-network-js": () => import("./../../../src/pages/network.js" /* webpackChunkName: "component---src-pages-network-js" */),
  "component---src-pages-nomination-js": () => import("./../../../src/pages/nomination.js" /* webpackChunkName: "component---src-pages-nomination-js" */),
  "component---src-pages-validation-js": () => import("./../../../src/pages/validation.js" /* webpackChunkName: "component---src-pages-validation-js" */)
}

